export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.numberWithCommas = function (number) {
            if (!(number + '').includes('.')) {
                return (number + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else {
                return `${((number + '').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(number + '').split('.')[1]}`
            }
        }

        // Vue.config.globalProperties.qnaState = function (status) {
        //     if (status === 1) {
        //         return '답변대기'
        //     } else if (status === 2) {
        //         return '답변완료'
        //     }
        //     return ''
        // }

        Vue.config.globalProperties.planKind = function (kind) {
            if (kind === '1') {
                return '드레스룸'
            } else if (kind === '2') {
                return '펜트리'
            }
            return ''
        }

        Vue.config.globalProperties.planType = function (type) {
            if (type === '1') {
                return '_'
            } else if (type === '2') {
                return 'ㄱ'
            } else if (type === '3') {
                return 'ㄷ'
            } else if (type === '4') {
                return 'ㅁ'
            } else if (type === '5') {
                return 'ㅡ+ㄱ'
            } else if (type === '6') {
                return '11'
            }
            return ''
        }

        Vue.config.globalProperties.typeOption = function (value) {
            if (value === '0') {
                return '없음'
            } else if (value === '1') {
                return '있음'
            }
            return ''
        }
    }
})
