<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도면 관리</h4>
            <span>도면 정보를 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row mb-1">
              <input type="text" class="form-control w-auto mr-1" placeholder="도면파일 이름" v-model.trim="searchWord">
              <select class="form-control w-auto mr-1" v-model="searchKind">
                <option value="">종류</option>
                <option value="1">드레스룸</option>
                <option value="2">펜트리</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="searchType">
                <option value="">타입</option>
                <option value="1">ㅡ</option>
                <option value="2">ㄱ</option>
                <option value="3">ㄷ</option>
                <option value="4">ㅁ</option>
                <option value="5">ㅡ+ㄱ</option>
                <option value="6">11</option>
              </select>
              <input inputmode="numeric" type="text" class="form-control mr-1" style="width: 65px;" placeholder="치수1"
                     v-model.trim="searchSize1">
              <input inputmode="numeric" type="text" class="form-control mr-1" style="width: 65px;" placeholder="치수2"
                     v-model.trim="searchSize2">
              <input inputmode="numeric" type="text" class="form-control mr-1" style="width: 65px;" placeholder="치수3"
                     v-model.trim="searchSize3">
              <input inputmode="numeric" type="text" class="form-control mr-1" style="width: 65px;" placeholder="치수4"
                     v-model.trim="searchSize4">
              <select class="form-control w-auto mr-1" v-model="searchDrawer">
                <option value="">서랍(모두)</option>
                <option value="1">서랍(있음)</option>
                <option value="0">서랍(없음)</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="searchDivide">
                <option value="">디바이드(모두)</option>
                <option value="1">디바이드(있음)</option>
                <option value="0">디바이드(없음)</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="searchMirror">
                <option value="">전신거울(모두)</option>
                <option value="1">전신거울(있음)</option>
                <option value="0">전신거울(없음)</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="searchIreland">
                <option value="">아일랜드(모두)</option>
                <option value="1">아일랜드(있음)</option>
                <option value="0">아일랜드(없음)</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="searchCabinet">
                <option value="">이불장(모두)</option>
                <option value="1">이불장(있음)</option>
                <option value="0">이불장(없음)</option>
              </select>
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
              </select>
              <button class="btn btn-primary" @click="goSearch()">검색</button>
            </div>
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success mr-1" @click="$router.push({ name: 'PlanRegister' })">도면 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">도면 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>종류</th>
                    <th>타입</th>
                    <th>도면파일</th>
                    <th>상세</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ planKind(item.kind) }}</td>
                    <td>{{ planType(item.type) }}</td>
                    <td>{{ item.drawingImgName }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goDetail(item.id)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "PlanManage",
  path: '/plan/manage',
  components: {
    Pagination
  },
  data() {
    return {
      searchWord: '',
      searchKind: '',
      searchType: '',
      searchSize1: '',
      searchSize2: '',
      searchSize3: '',
      searchSize4: '',
      searchDrawer: '',
      searchDivide: '',
      searchMirror: '',
      searchIreland: '',
      searchCabinet: '',

      items: [],
      size: 10,
      page: 1,
      totalCount: 0,
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'admin_plan', 'manage')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }
    if (this.$route.query.searchWord) {
      this.searchWord = this.$route.query.searchWord
    }
    if (this.$route.query.searchKind) {
      this.searchKind = this.$route.query.searchKind
    }
    if (this.$route.query.searchType) {
      this.searchType = this.$route.query.searchType
    }
    if (this.$route.query.searchSize1) {
      this.searchSize1 = this.$route.query.searchSize1
    }
    if (this.$route.query.searchSize2) {
      this.searchSize2 = this.$route.query.searchSize2
    }
    if (this.$route.query.searchSize3) {
      this.searchSize3 = this.$route.query.searchSize3
    }
    if (this.$route.query.searchSize4) {
      this.searchSize4 = this.$route.query.searchSize4
    }
    if (this.$route.query.searchDrawer) {
      this.searchDrawer = this.$route.query.searchDrawer
    }
    if (this.$route.query.searchDivide) {
      this.searchDivide = this.$route.query.searchDivide
    }
    if (this.$route.query.searchMirror) {
      this.searchMirror = this.$route.query.searchMirror
    }
    if (this.$route.query.searchIreland) {
      this.searchIreland = this.$route.query.searchIreland
    }
    if (this.$route.query.searchCabinet) {
      this.searchCabinet = this.$route.query.searchCabinet
    }

    this.getList(this.page)
  },
  methods: {
    goSearch() {
      let query = {
        page: 1 + '',
        size: this.size + ''
      }

      if (this.searchWord !== '') {
        query.searchWord = this.searchWord
      }
      if (this.searchKind !== '') {
        query.searchKind = this.searchKind
      }
      if (this.searchType !== '') {
        query.searchType = this.searchType
      }
      if (this.searchSize1 !== '') {
        query.searchSize1 = this.searchSize1
      }
      if (this.searchSize2 !== '') {
        query.searchSize2 = this.searchSize2
      }
      if (this.searchSize3 !== '') {
        query.searchSize3 = this.searchSize3
      }
      if (this.searchSize4 !== '') {
        query.searchSize4 = this.searchSize4
      }
      if (this.searchDrawer !== '') {
        query.searchDrawer = this.searchDrawer
      }
      if (this.searchDivide !== '') {
        query.searchDivide = this.searchDivide
      }
      if (this.searchMirror !== '') {
        query.searchMirror = this.searchMirror
      }
      if (this.searchIreland !== '') {
        query.searchIreland = this.searchIreland
      }
      if (this.searchCabinet !== '') {
        query.searchCabinet = this.searchCabinet
      }

      this.$router.replace({name: this.$options.name, query: query})
      this.getList(1)
    },
    getList(_page) {
      let queryString = ''

      if (this.searchWord !== '') {
        queryString = queryString + '&searchWord=' + this.searchWord
      }
      if (this.searchKind !== '') {
        queryString = queryString + '&kind=' + this.searchKind
      }
      if (this.searchType !== '') {
        queryString = queryString + '&type=' + this.searchType
      }
      if (this.searchSize1 !== '') {
        queryString = queryString + '&size1=' + this.searchSize1
      }
      if (this.searchSize2 !== '') {
        queryString = queryString + '&size2=' + this.searchSize2
      }
      if (this.searchSize3 !== '') {
        queryString = queryString + '&size3=' + this.searchSize3
      }
      if (this.searchSize4 !== '') {
        queryString = queryString + '&size4=' + this.searchSize4
      }
      if (this.searchDrawer !== '') {
        queryString = queryString + '&option1=' + this.searchDrawer
      }
      if (this.searchDivide !== '') {
        queryString = queryString + '&option2=' + this.searchDivide
      }
      if (this.searchMirror !== '') {
        queryString = queryString + '&option3=' + this.searchMirror
      }
      if (this.searchIreland !== '') {
        queryString = queryString + '&option4=' + this.searchIreland
      }
      if (this.searchCabinet !== '') {
        queryString = queryString + '&option5=' + this.searchCabinet
      }

      this.$get(this.$DRAWING + '?page=' + _page + '&perPage=' + this.size + queryString, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total
        this.page = _page

        /* 페이지네이션 */
        try {
          this.$refs.Pagination.init(this.$options.name, null, {
            searchWord: this.searchWord,
            searchKind: this.searchKind,
            searchType: this.searchType,
            searchSize1: this.searchSize1,
            searchSize2: this.searchSize2,
            searchSize3: this.searchSize3,
            searchSize4: this.searchSize4,
            searchDrawer: this.searchDrawer,
            searchDivide: this.searchDivide,
            searchMirror: this.searchMirror,
            searchIreland: this.searchIreland,
            searchCabinet: this.searchCabinet,
          }, this.page, this.totalCount, Number(this.size))

        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goDetail(_id) {
      this.$router.push({name: 'PlanManageDetail', query: {id: _id}})
    },
  },
  watch: {
    searchSize1() {
      if (!(/^[0-9]*$/.test(this.searchSize1))) {
        this.searchSize1 = /([0-9]*)/.exec(this.searchSize1)[0]
      }
    },
    searchSize2() {
      if (!(/^[0-9]*$/.test(this.searchSize2))) {
        this.searchSize2 = /([0-9]*)/.exec(this.searchSize2)[0]
      }
    },
    searchSize3() {
      if (!(/^[0-9]*$/.test(this.searchSize3))) {
        this.searchSize3 = /([0-9]*)/.exec(this.searchSize3)[0]
      }
    },
    searchSize4() {
      if (!(/^[0-9]*$/.test(this.searchSize4))) {
        this.searchSize4 = /([0-9]*)/.exec(this.searchSize4)[0]
      }
    },
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>