import axios from 'axios'

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}
let requestOptionWithFile = () => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}
let requestOptionWithTokenForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko',
        },
        timeout: 60000,
        responseType: 'blob',
    }
}

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.$DOMAIN = process.env.VUE_APP_DOMAIN + "/admin"
        Vue.config.globalProperties.$IMAGE_DOMAIN = process.env.VUE_APP_IMAGE_DOMAIN /*이미지 main domain*/

        Vue.config.globalProperties.$AUTH = "/auth" /*인증-로그인*/

        Vue.config.globalProperties.$SETTING_ADMIN = "/setting/admin" /*관리자 계정 리스트*/
        Vue.config.globalProperties.$SETTING_ADMIN_REGISTER = "/setting/admin" /*관리자 계정 등록*/
        Vue.config.globalProperties.$SETTING_ADMIN_EDIT = "/setting/admin/" /*관리자 계정 수정 : id*/
        Vue.config.globalProperties.$SETTING_ADMIN_DELETE = "/setting/admin/" /*관리자 계정 삭제 : id*/

        Vue.config.globalProperties.$SETTING_ACCOUNT = "/setting/account" /*사용자 계정 리스트*/
        Vue.config.globalProperties.$SETTING_ACCOUNT_REGISTER = "/setting/account" /*사용자 계정 등록*/
        Vue.config.globalProperties.$SETTING_ACCOUNT_EDIT = "/setting/account/" /*사용자 계정 수정 : id*/
        Vue.config.globalProperties.$SETTING_ACCOUNT_DELETE = "/setting/account/" /*사용자 계정 삭제 : id*/

        Vue.config.globalProperties.$DRAWING = "/drawing" /*도면 관리, 도면 목록*/
        Vue.config.globalProperties.$DRAWING_REGISTER = "/drawing" /*도면 등록*/
        Vue.config.globalProperties.$DRAWING_DETAIL = "/drawing/" /*도면 상세 : id*/
        Vue.config.globalProperties.$DRAWING_EDIT = "/drawing/" /*도면 정보 수정 : id*/
        Vue.config.globalProperties.$DRAWING_DELETE = "/drawing/" /*도면 정보 삭제 : id*/
        Vue.config.globalProperties.$DRAWING_DOWNLOAD = "/drawing/download" /*도면 파일 다운로드*/

        Vue.config.globalProperties.$post = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                if (callUrl.includes(this.$DRAWING_REGISTER) || callUrl.includes(this.$DRAWING_EDIT)) {
                    _requestOption = requestOptionWithFile()
                } else {
                    _requestOption = requestOptionWithToken()
                }
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.status === 200 || response.status === 201 || response.status === 205) {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false

                if (response.status === 205) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false

                if (response.status === 204) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false

                if (response.status === 200) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$download = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let token = sessionStorage.getItem('token')
            if (token && token.length > 0) {
                let _requestOption = requestOptionWithTokenForDownload()

                axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                    pending_post[arguments[0] + caller] = false

                    if (response.headers['content-type'] === 'application/vnd.sketchup.skp') {
                        success(response)
                    } else {
                        fail(response.data)
                    }

                }).catch((e) => {
                    pending_post[arguments[0] + caller] = false
                    fail(e.response)
                })
            } else {
                this.$router.replace({name: 'Login'})
            }
        }

        Vue.config.globalProperties.$fileDownload = function (response, name) {
            // let filename = response.headers['content-disposition'].split('filename=')[1].split('"')[1]
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('style', 'display:none')
            link.setAttribute('download', name)
            link.innerText = '다운로드'
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
            // return [link, filename]
        }
    }
})
