<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo">
        <a><b>드레스룸 도면</b></a>
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body text-center">
          <p class="login-box-msg">이메일과 비밀번호를 입력해주세요</p>
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Email" v-model.trim="email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-id-badge"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="Password" v-model.trim="password"
                   @keyup.enter="login()">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>

          <div class="input-group mb-2">
            <div class="input-group-prepend w-100">
              <div class="form-check form-check-inline ml-1">
                <input class="form-check-input" type="checkbox" name="saveid" id="kindRadio1" v-model="saveid">
                <label class="form-check-label" for="kindRadio1">아이디저장</label>
              </div>
              <div class="form-check form-check-inline ml-auto">
                <input class="form-check-input" type="checkbox" name="autologin" id="kindRadio2" v-model="autologin">
                <label class="form-check-label" for="kindRadio2">자동로그인</label>
              </div>
            </div>
          </div>

          <button class="btn btn-primary" @click="login()">로그인</button>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  path: '/',
  meta: {
    hideLNBHeader: true
  },
  components: {},
  data() {
    return {
      email: '',
      password: '',
      saveid: false,
      autologin: false,
    }
  },
  created() {
    sessionStorage.removeItem('token')
  },
  mounted() {
    this.saveid = localStorage.getItem('saveid')
    this.autologin = localStorage.getItem('autologin')
    this.email = localStorage.getItem('email')
    this.password = localStorage.getItem('password')

    if (this.autologin && this.email && this.password) {
      this.login()
    }
  },
  methods: {
    login() {
      if (this.email === '') {
        this.notify('error', '이메일을 입력해주세요.')
        return
      }
      if (this.password === '') {
        this.notify('error', '비밀번호를 입력해주세요.')
        return
      }

      this.$post(this.$AUTH + '?email=' + this.email + '&password=' + encodeURIComponent(this.password), this.$options.name + '_login', null, (result) => {
        sessionStorage.setItem('token', result.data.access_token)

        if (this.saveid) {
          localStorage.setItem('email', this.email)
          localStorage.setItem('saveid', this.saveid)
        } else {
          localStorage.removeItem('email')
          localStorage.removeItem('saveid')
        }

        if (this.autologin) {
          localStorage.setItem('email', this.email)
          localStorage.setItem('saveid', true)
          localStorage.setItem('password', this.password)
          localStorage.setItem('autologin', this.autologin)
        } else {
          localStorage.removeItem('password')
          localStorage.removeItem('autologin')
        }

        // 최고관리자(master), 관리자(admin), 일반사용자(user)
        let role = result.data.role
        sessionStorage.setItem('role', role)
        this.emitter.emit(this.$EVENT_SET_LNB)

        // 사용자 타입에 따라 진입화면 구분
        if (role.includes('master')){
        this.$router.replace({name: 'AdminAccount'})
        } else if (role.includes('admin')){
          this.$router.replace({name: 'PlanManage'})
        } else if (role.includes('user')){
          this.$router.replace({name: 'PlanList'})
        }

      }, (result) => {
        this.httpError(result)
      })
    },
  }
}
</script>

<style>
.dark-mode .login-page {
  background-color: #454d55 !important;
}
</style>
