import {createRouter, createWebHashHistory} from "vue-router"

import Login from '@/components/Login'
import AdminAccount from "@/components/admin/account/AdminAccount";
import UserAccount from "@/components/admin/account/UserAccount";
import PlanManage from "@/components/admin/plan/PlanManage";
import PlanManageDetail from "@/components/admin/plan/PlanManageDetail";
import PlanRegister from "@/components/admin/plan/PlanRegister";
import PlanList from "@/components/user/plan/PlanList";
import PlanDetail from "@/components/user/plan/PlanDetail";

export default createRouter({
    history: createWebHashHistory(),//2.x대를 설치하시면 작동을 안합니다.
    scrollBehavior (){
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                hideLNBHeader: true
            },
        },
        {
            path: '/admin/account',
            name: 'AdminAccount',
            component: AdminAccount,
        },
        {
            path: '/user/account',
            name: 'UserAccount',
            component: UserAccount,
        },
        {
            path: '/plan/Manage',
            name: 'PlanManage',
            component: PlanManage,
        },
        {
            path: '/plan/Manage/detail',
            name: 'PlanManageDetail',
            component: PlanManageDetail,
        },
        {
            path: '/plan/register',
            name: 'PlanRegister',
            component: PlanRegister,
        },
        {
            path: '/plan/list',
            name: 'PlanList',
            component: PlanList,
        },
        {
            path: '/plan/detail',
            name: 'PlanDetail',
            component: PlanDetail,
        },
    ],
})