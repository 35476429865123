<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도면 상세</h4>
            <span>도면 상세 내용을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">

          <div :class="{ 'col-md-6' : dressRoomImg.length > 0, 'd-none' : dressRoomImg.length <= 0}">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">시공이미지 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <div class="row">
                        <div v-for="(img, index) in dressRoomImg" :key="index" class="col-4 text-center">
                          <img :src="$IMAGE_DOMAIN + img.path" alt="시공 이미지" class="img-thumbnail"
                               @click="goPreview($IMAGE_DOMAIN + img.path)">
                          <p class="pt-1 text-truncate">{{ img.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->

          <div :class="{ 'col-md-6' : dressRoomImg.length > 0, 'col-12' : dressRoomImg.length <= 0 }">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">도면 상세 정보</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>종류</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ planKind(kind) }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>타입</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ planType(type) }}</span>
                        </div>
                      </div>
                      <div class="mb-2 border-bottom"></div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>치수1</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ size1 }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>치수2</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ size2 }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>치수3</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ size3 }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>치수4</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ size4 }}</span>
                        </div>
                      </div>
                      <div class="mb-2 border-bottom"></div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>서랍</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ typeOption(drawer) }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>디바이드</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ typeOption(divide) }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>전신거울</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ typeOption(mirror) }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>아일랜드</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ typeOption(ireland) }}</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>이불장</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ typeOption(cabinet) }}</span>
                        </div>
                      </div>

                      <div class="mb-2 border-bottom"></div>
                      <!-- 도면 이미지 -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>도면 파일</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>{{ planFileName }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->

          <div class="col-12">
            <div class="d-flex mt-3 mb-3">
              <input type="button" class="btn btn-secondary ml-auto mr-auto" value="목록으로" @click="$router.back()"/>
            </div>
          </div>

        </div><!-- /.row -->
      </div>
    </section>
  </div>

  <div class="modal fade" id="imgPreviewModal" tabindex="-1" aria-labelledby="imgPreviewModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="imgPreviewModalLabel">{{ previewImgName }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <img class="w-100" :src="previewImg" alt="시공 이미지">
        </div>
      </div>
    </div>
  </div>

  <!--  <a href="https://www.flaticon.com/kr/free-icons/" title="사양 아이콘">사양 아이콘  제작자: Circlon Tech - Flaticon</a>-->
</template>

<script>
export default {
  name: "PlanDetail",
  path: '/plan/detail',
  data() {
    return {
      kind: '1',
      type: '1',
      size1: '',
      size2: '',
      size3: '',
      size4: '',
      drawer: '0',
      divide: '0',
      mirror: '0',
      ireland: '0',
      cabinet: '0',
      planFileName: '',
      dressRoomImg: [],
      previewImg: '',
      previewImgName: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'user_plan', 'list')
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$DRAWING_DETAIL + this.$route.query.id, this.$options.name + '_getData', (result) => {
        this.kind = result.data.kind
        this.type = result.data.type
        if (result.data.size1 > 0) {
          this.size1 = result.data.size1
        }
        if (result.data.size2 > 0) {
          this.size2 = result.data.size2
        }
        if (result.data.size3 > 0) {
          this.size3 = result.data.size3
        }
        if (result.data.size4 > 0) {
          this.size4 = result.data.size4
        }
        this.drawer = result.data.option1
        this.divide = result.data.option2
        this.mirror = result.data.option3
        this.ireland = result.data.option4
        this.cabinet = result.data.option5

        this.planFileName = result.data.drawingImgName

        this.dressRoomImg = result.data.constructionImg

      }, (result) => {
        this.httpError(result)
      })
    },
    goPreview(_img) {
      let imgFile = _img.split('/')
      this.previewImgName = imgFile[imgFile.length - 1]
      this.previewImg = _img

      window.$('#imgPreviewModal').modal('show');
    }
  }
}
</script>

<style scoped>

</style>