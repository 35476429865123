<template>
  <div>
    <ul class="pagination pagination-sm m-0">
      <li class="page-item" v-bind:class="{ disabled: prevTab === 0 }">
        <router-link class="page-link" :to="{ name:route,params:params,query:getQueries(prevTab) }">&laquo;
        </router-link>
      </li>
      <li class="page-item" v-bind:class="{ disabled: prev === 0 }">
        <router-link class="page-link" :to="{ name:route,params:params,query:getQueries(prev) }">&lt;</router-link>
      </li>
      <li :id="'page' + n" class="page-item page" v-for="n in items" v-bind:key="n"
          v-bind:class="{ active: n === page }">
        <router-link class="page-link" :to="{ name:route,params:params,query:getQueries(n) }">{{ n }}</router-link>
      </li>
      <li class="page-item" v-bind:class="{ disabled: next === 0 }">
        <router-link class="page-link" :to="{ name:route,params:params,query:getQueries(next) }">&gt;</router-link>
      </li>
      <li class="page-item" v-bind:class="{ disabled: nextTab === 0 }">
        <router-link class="page-link" :to="{ name:route,params:params,query:getQueries(nextTab) }">&raquo;
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      prevTab: 0,
      prev: 0,
      page: 0,
      next: 0,
      nextTab: 0,
      total: 0,
      totalPage: 0,
      size: 0,
      tab: 0,

      items: [],

      route: '',
      queries: null,
      params: null,
    }
  },
  methods: {
    init(route, params, queries, page, total, size, tab = 10,) {
      this.route = route
      this.queries = queries
      this.page = page
      this.total = total
      this.size = size
      this.tab = tab
      this.params = params
      this.totalPage = Math.floor((this.total - 1) / this.size) + 1

      this.next = this.page + 1
      if (this.next > this.totalPage) {
        this.next = 0
      }

      this.prev = this.page - 1
      if (this.prev < 0) {
        this.prev = 0
      }

      this.nextTab = this.page + this.tab
      if (this.nextTab > this.totalPage) {
        this.nextTab = 0
      }

      this.prevTab = this.page - this.tab
      if (this.prevTab < 0) {
        this.prevTab = 0
      }

      this.items = []
      let start = Math.floor((this.page - 1) / 10) * 10 + 1
      let end = start + this.tab - 1
      if (end > this.totalPage) {
        end = this.totalPage
      }

      for (let i = start; i <= end; i++) {
        this.items.push(i)
      }

      this.$nextTick(function () { /*검색 후, 새로고침시 active 풀려서 추가*/
        if (queries != null) {
          for (let i = 1; i <= this.items.length; i++) {
            window.$(document.getElementById('page' + i)).removeClass('active');
          }

          window.$(document.getElementById('page' + page)).addClass('active')
        }
      });
    },
    getQueries(page) {
      let queries = {...this.queries}
      if (queries) {
        queries.page = page
        queries.size = this.size
      }

      return queries
    },
  },
}
</script>

<style>
</style>
